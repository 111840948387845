import * as React from 'react';
import SessionHandler from "./SessionHandler";
import {Props, sessionValue} from './interfaces';

export const SessionContext = React.createContext({});
export const ContextProvider = SessionContext.Provider;

const {useEffect, useState} = React;

/**
 * This component allows to connect with the session storage for the appliation
 * @author Alejandro Quiroz <alejandro.devop@gmail.com>
 * @version 1.0.0
 * @param param0 
 * @returns 
 */
const SessionProvider: React.FC<Props> = ({
      children,
      pKey,
      sessionName = "__app_session__",
      mask = "|%|&|^",
      salt = "5SPZxh",
  }) => {
    const sessionHandler = new SessionHandler(pKey, salt, mask, sessionName);
    const [store, setStore] = useState(sessionHandler.getStore());
    const setKey = async (key: string, value: sessionValue): Promise<boolean> => {
        const newStore = await sessionHandler.set(key, value);
        setStore(newStore);
        return true;
    };

    const deleteKey = async (key: string): Promise<boolean> => {
        const newStore = await sessionHandler.deleteKey(key);
        setStore(newStore);
        return true;
    };

    const setAll = async (keys: {[key: string]: sessionValue}): Promise<boolean> => {
        const newStore = await sessionHandler.setAll(keys);
        setStore(newStore);
        return true;
    };

    const clear = async () => {
        await sessionHandler.clear()
        setStore({})
    }

    useEffect(() => {
        setStore(sessionHandler.initialize());
    }, []);

    return (
        <ContextProvider value={{
            _dk: deleteKey,
            _sa: setAll,
            _sk: setKey,
            _s: store,
            _c: clear
        }}>
            {children}
        </ContextProvider>
    );
};

export default SessionProvider;
