import Seizo from '../utils/Saizo'
import {sessionValue} from './interfaces';
type SessionType = {[key: string]: sessionValue};

/**
 * Class to persist the session data into localStorage or using a cookie
 * Note: This class uses a simple and reversible encryption for the session, the security of your session data
 * depends entirely on how do you protect your keys, this is because your session data encrypted will be saved on
 * your browser. So keep in mind you have to protect your keys by all means.
 * @author Alejandro <alejandro.devop@gmail.com>
*  @version 1.0.0
 */
class SessionHandler {
    /**
     * Copy from the persisted data
     */
    store = {};
    /**
     * driver used to save the storage data.
     */
    driver: 'localStorage' | 'cookie' = 'localStorage';
    /**
     * A name given to the application session.
     */
    sessionName: string = '__app_store__';
    /**
     * A string used to encrypt the session
     */
    salt: string = '';
    /**
     * Another string used to encrypt the session.
     */
    key: string = '';
    /**
     * Another string used to make more difficult to resolve the session.
     */
    mask: string = '';

    constructor(key: string, salt: string, mask: string, sessionName: string) {
        this.salt = salt;
        this.key = key;
        this.mask = mask;
        this.sessionName = sessionName;
        this.hydrateStorage();
    }

    /**
     * Function to add any initializing value for the session handler.
     */
    initialize(): SessionType {
        return this.hydrateStorage();
    }

    /**
     * Function to retrieve the data from the browser store and save it into the
     * class store.
     */
    private hydrateStorage(): SessionType {
        try {
            if (this.driver === 'cookie') {
                // Todo: Add cookie support
                return {}
            } else {
                const storageVal = localStorage.getItem(this.sessionName);
                this.store = storageVal? this.decrypt(storageVal) : {};
                return this.store;
            }
        } catch (e) {
            throw e;
        }
        return {}
    }

    /**
     * Function to save a new key into the storage.
     * @param key
     * @param value
     */
    async set(key: string, value: sessionValue): Promise<SessionType> {
        return new Promise((resolve) => {
            this.store[key] = value;
            this.persist();
            resolve({...this.store});
        });
    }

    /**
     * Function to remove a key from the storage.
     * @param key
     */
    async deleteKey(key: string): Promise<SessionType> {
        return new Promise(resolve => {
            delete this.store[key];
            this.persist();
            resolve({...this.store});
        });
    }

    /**
     * Function to set multiple keys into the storage.
     * @param keys
     */
    setAll(keys: {[key: string]: sessionValue}): Promise<SessionType> {
        return new Promise(resolve => {
            this.store = {
                ...this.store,
                ...keys,
            };
            this.persist();
            resolve({...this.store});
        });
    }

    /**
     * Function to retrieve all session object (A copy)
     */
    getStore(): SessionType {
        return {...this.store};
    }

    persist(): void {
        const encrypted = this.encrypt(this.store);
        if (this.driver === 'cookie') {
            // Handle cookies on session
        } else {
            localStorage.setItem(this.sessionName, encrypted);
        }
    }

    /**
     * Function to clear all session data.
     */
    clear(): Promise<SessionType> {
        return new Promise(resolve => {
            this.store = {};
            this.persist();
            resolve({});
        });
    }

    /**
     * Function to encrypt the session data
     * @param obj
     */
    private encrypt (obj: SessionType): string {
        const d = JSON.stringify(obj)
        return Seizo._e(d, this.key, this.mask, this.salt);
    }

    /**
     * Function to decrypt the session data.
     * @param target
     */
    private decrypt(target: string): SessionType {
        return Seizo._d(target, this.key, this.mask, this.salt);;
    }
}

export default SessionHandler;
