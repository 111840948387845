import _C from 'crypto-js';

/**
 * Simple encryption and decryption library, i'll just skip speak to much on this algorithm, it's
 * ridiculous mixed up.
 * @author Alejandro <alejandro.devop@gmail.com>
 * @version 1.0.0
 */
class Seizo {
    a: string = 'abcdefghijklmnopqrstuvwxyz';

    _d(_i: string, _k: string, _m: string, _s: string): {[key: string]: any} {
        let r:string = this._rs(_i);
        const __s: string = this._rs(Seizo.__e(_s));
        r = r.replace(__s, '');
        r = r.substr(0, r.length - 1);
        r = r.replace(this._gm(0, _m, _s), '');
        r = r.replace(this._gm(1, _m, _s), '');
        r = r.replace(this._gm(2, _m, _s), '');
        const bp = Math.floor(r.length / 2);
        const __fp__ = this._rs(r.substr(0, bp));
        const __lp__ = this._rs(r.substr(bp));
        return JSON.parse(JSON.parse(Seizo.__d(`${__fp__}${__lp__}`)));
    }

    _gm(___i: number, ____m: string, ___s: string): string {
        const _c = ____m[0];
        const __p = ____m.substr(1).split(_c);
        let __mr = Seizo.__e(`${___s}${__p[___i]}`);
        const __c = (__mr.match(/=/g) || []).length;
        return Seizo.__e(__mr.replace(/=/g, `${__c > 0? __c : ''}`));
    }

    _e(__i: string, __k: string, __n: string, _s: string): string {
        let _t = JSON.stringify(__i);
        const __e = Seizo.__e(_t);
        const bp = Math.floor(__e.length / 2);
        const t = this.a[Math.floor(Math.random() * this.a.length)];
        const __fp__ = this._rs( __e.substr(0, bp));
        const __lp__ = this._rs( __e.substring(bp));
        return this._rs(
            `${this._gm(0, __n, _s)}${__fp__}${this._gm(1, __n, _s)}${__lp__}${this._gm(2, __n, _s)}${t}${this._rs(Seizo.__e(_s))}`
        );
    }

    static __e(_s: string): string {
        const _w = _C.enc.Utf8.parse(_s);
        return _C.enc.Base64.stringify(_w);
    }

    static __d(_s: string): string {
        const _w = _C.enc.Base64.parse(_s);
        return _w.toString(_C.enc.Utf8);
    }

    _rs(_s: string): string {
        return _s.split('').reverse().join('')
    };
}

export default new Seizo();
