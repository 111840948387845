import * as React from 'react';
import {SessionContext} from '../session-provider/SessionContext';
import {SessionValueInterface, SessionContextInterface} from "../session-provider/interfaces";
const {
    useContext,
} = React;

/**
 * Hook which provides access to the session functions and store.
 * @author Alejandro <alejandro.devop@gmail.com>
 * @version 1.0.0
 */
const useSession = <T extends object>(): SessionValueInterface<T> => {
    const {
        _dk,
        _sa,
        _sk,
        _s,
        _c
    } = useContext(SessionContext) as SessionContextInterface;
    return {
        deleteKey: _dk,
        setAll: _sa,
        setKey: _sk,
        store: _s,
        clear: _c
    };
};

export default useSession;
